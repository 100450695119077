import styled from 'styled-components'
import colors from '../../utils/style/colors'
import { StyledButton } from '../../utils/style/Atoms'
import { useTheme } from '../../utils/hooks'
import React from 'react'
import * as yup from "yup";
import { useFormik } from 'formik';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Form = styled.form`
    padding: 30px 30px;
    color: ${colors.white};
    flew-wrap: wrap;
    max-width: 500px;
    transition: all 200ms;
    ${StyledButton} {
        margin: 10px 0 0 5px;
        box-shadow: ${({ theme }) => (theme === 'light' ? `4px 4px 8px ${colors.black}` : `8px 8px 12px ${colors.black}` )};
        &:hover {
            box-shadow: ${({ theme }) => (theme === 'light' ? `6px 6px 10px ${colors.black}` : `8px 8px 12px ${colors.black}` )};
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        text-align: center;
    }
`

const FormTitle = styled.legend`
    @media screen and (max-width: 980px) {
        margin-top: 0;
    }
`

const FieldTitle = styled.label`
    margin: 10px 0 5px 0;
    display: block;
`

const Alert = styled.p`
    color: ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary )};
    margin: 5px 0 0 5px;
`

const CharsLeft = styled.span`
    max-width: 480px;
    text-align: left;
    margin: 5px 0 0 5px;
    display: block;
    @media screen and (max-width: 480px) {
        text-align: center;
        font-size: 15px;
        margin-left: auto;
        margin-right: auto;
    }

`

const Constraint = styled.p`
    margin: 10px 0 0 0;
    @media screen and (min-width: 480px) {
        text-align: end;
    }
`
const Asterisk = styled.span`
    color: ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary )};
    font-weight: bold;
`


function ContactForm() {
    const { theme } = useTheme()
    const phoneRegEx = /^0[1-9](([. -]?)[0-9]{2}){1}(\2[0-9]{2}){3}$/;
    const MySwal = withReactContent(Swal)

    const SuccessAlert = () => {
        MySwal.fire({
            icon: 'success',
            title: 'Votre message a bien été envoyé',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })
    };

    const ErrorAlert = () => {
        MySwal.fire({
            icon: 'error',
            title: 'Désolé, nous rencontrons un problème momentané avec le formulaire. Merci de nous contacter par e-mail ou par téléphone',
        })
    };

    const formik = useFormik({
        initialValues: {
          name: '', 
          email: '', 
          phone: '', 
          message: '',
        },
        validationSchema: yup.object({
            name: yup
            .string("Ceci ne semble pas être un nom")
            .required("Merci de renseigner votre nom")
            .max(30, "Nom : 50 caractères au maximum")
            .min(2, "Nom : 2 caractères au minimum"),
          email: yup
            .string()
            .required("Merci de renseigner votre adresse e-mail")
            .email("Adresse mail non conforme")
            .min(3, "Adresse mail non conforme")
            .max(50, "E-mail : 50 caractères au maximum"),
          phone: yup
              .string()
              .matches(phoneRegEx, 'Ce numéro de téléphone semble incorrect')
              .max(20, "Votre téléphone doit contenir 20 caractères au maximum")
              .min(8, "Votre téléphone doit contenir 8 caractères au minimum"),
          message: yup
              .string("Le contenu de votre message ne semble pas conforme")
              .required("Votre message est vide")
              .max(3000, "Votre message doit contenir 3000 caractères au maximum")
              .min(30, "Votre message doit contenir 30 caractères au minimum"),
        }),
        onSubmit: (values, { resetForm }) => {
            try {
                emailjs.send("service_ozlfnde", "template_gpriutr", values, "hH0t4CwUsEzowurof")
                .then((result) => {
                    console.log(result.text + ' Message envoyé avec succès');
                    SuccessAlert()
                    resetForm()
                })
            } catch(error) {
                console.log(error.text + ' Erreur lors de l\'envoi avec EmailJS');
                ErrorAlert()
            }
        }
    });

    let messageCharsLeft = 3000 - formik.values.message.length;

    return (
        <Form className="container bgc-white mg-md" id="#contact_form" method="POST" onSubmit={formik.handleSubmit} theme={theme} noValidate>
            <FormTitle>Formulaire de contact</FormTitle>
            
            <FieldTitle htmlFor='name'>Votre nom <Asterisk theme={theme}> *</Asterisk>
                <input type="text" name="name" id="name" placeholder="Nom (obligatoire)" className="form-control mt-1"
                    aria-invalid={formik.errors.name ? "true" : "false"} aria-describedby= {formik.errors.name && formik.touched.name ? 'message-error' : null} onChange={formik.handleChange} value={formik.values.name}
                    aria-required="true"
                    autoComplete="on"
                />
            </FieldTitle>
            {formik.touched.name && formik.errors.name ? 
                <Alert theme={theme}>
                    {formik.errors.name}
                </Alert> 
            : null}
            
            <FieldTitle htmlFor='email'>Votre adresse e-mail <Asterisk theme={theme}> *</Asterisk>
                <input type="email" name="email" id="email" placeholder="E-mail (obligatoire)" className="form-control mt-1" 
                    aria-invalid={formik.errors.email ? "true" : "false"} aria-describedby= {formik.errors.email && formik.touched.email ? 'message-error' : null} onChange={formik.handleChange} value={formik.values.email}
                    aria-required="true"
                    autoComplete="on"
                />
            </FieldTitle>
            {formik.touched.email && formik.errors.email ? 
                <Alert theme={theme}>
                    {formik.errors.email}
                </Alert> 
            : null}

            <FieldTitle htmlFor='phone'>Votre numéro de téléphone
                <input type="tel" name="phone" id="phone" placeholder="Numéro de téléphone (facultatif)" className="form-control mt-1"
                    aria-invalid={formik.errors.phone ? "true" : "false"} aria-describedby= {formik.errors.phone && formik.touched.phone ? 'message-error' : null} onChange={formik.handleChange} value={formik.values.phone}
                    aria-required="false"
                    autoComplete="on"
                />
            </FieldTitle>
            {formik.touched.phone && formik.errors.phone ? 
                <Alert theme={theme}>
                    {formik.errors.phone}
                </Alert>
            : null}
        
            <FieldTitle htmlFor="message">
                Votre message <Asterisk theme={theme}> *</Asterisk>
            </FieldTitle>
            <textarea 
                id="message" name="message" className="form-control mt-1" rows="4" cols="50" placeholder="Merci de bien vouloir décrire votre projet en quelques lignes" 
                aria-invalid={formik.errors.message ? "true" : "false"} aria-describedby= {formik.errors.message && formik.touched.message ? 'message-error' : null} onChange={formik.handleChange} value={formik.values.message}
                aria-required="true"
                autoComplete="on"
            ></textarea>
            <CharsLeft className='message-chars-left'>{messageCharsLeft} / 3000 (maximum autorisé)</CharsLeft>
            {formik.touched.message && formik.errors.message ? 
                <Alert theme={theme}>
                    {formik.errors.message}
                </Alert>
            : null}
            
            <StyledButton type='submit' theme={theme} style={{margin: "15px 0 0 0"}}title='Envoyer ma demande'>Envoyer</StyledButton>
            <Constraint>champs obligatoires <Asterisk theme={theme}> *</Asterisk></Constraint>
        </Form>
    )
}

export default ContactForm
